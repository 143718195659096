const fbPixel = (fbPixelId) => {
	const script = document.createElement("script");
	script.type = "text/javascript";
	script.text = `!function(f,b,e,v,n,t,s)
                                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                                    n.queue=[];t=b.createElement(e);t.async=!0;
                                    t.src=v;s=b.getElementsByTagName(e)[0];
                                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                                    'https://connect.facebook.net/en_US/fbevents.js');
                                    fbq('init', '${fbPixelId}');
                                    fbq('track', 'PageView');
                                `;
	document.getElementsByTagName("head")[0].appendChild(script);
	const noscript = document.getElementsByTagName("noscript")[0];
	noscript.innerText = `< img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${fbPixelId}&ev=PageView&noscript=1"/>`;
};

const googleAnalytics = (id) => {
	const script1 = document.createElement("script");
	script1.type = "text/javascript";
	script1.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
	document.getElementsByTagName("head")[0].appendChild(script1);

	const script2 = document.createElement("script");
	script2.type = "text/javascript";
	script2.text = `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '${id}');`;
	document.getElementsByTagName("head")[0].appendChild(script2);

	// Google Analytics
	(function(i, s, o, g, r, a, m) {
		i["GoogleAnalyticsObject"] = r;
		(i[r] =
			i[r] ||
			function() {
				(i[r].q = i[r].q || []).push(arguments);
			}),
			(i[r].l = 1 * new Date());
		(a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
		a.async = 1;
		a.src = g;
		m.parentNode.insertBefore(a, m);
	})(window, document, "script", "https://www.google-analytics.com/analytics.js", "ga");
	window.ga("create", `${id}`, "auto");
	window.ga("send", "pageview");
};

export { fbPixel, googleAnalytics };
